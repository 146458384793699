import { defineStore } from 'pinia'

export const useAddressStore = defineStore('address', {
  state: () => ({
    countries: [],
    subDivisions: [],
    subSubDivisions: [],
    subSubSubDivisions: [],
    own: []
  }),
  actions: {
    updateById(list, item) {
      list.forEach(function(listItem, idx) {
        if (listItem.id === item.id) {
          list[idx] = item;
        }
      });
    },
    loadCountries() {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/countries`).then(function(response) {
          self.countries = response.data;
          resolve();
        });
      })
    },
    load() {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/addresses`).then(function(response) {
          self.own = response.data;
          resolve(response.data);
        });
      })
    },
    loadById(addressId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/addresses/${addressId}`).then(function(response) {
          self.updateById(self.own, response.data);
          resolve(response.data);
        });
      })
    },
    loadSubdivisions(countryId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/countries/${countryId}/subdivisions`).then(function(response) {
          self.subDivisions = response.data;
          self.subSubDivisions = [];
          self.subSubSubDivisions = [];
          resolve(response.data);
        });
      })
    },
    loadSubSubdivisions(subDivisionId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/subdivisions/${subDivisionId}/subsubdivisions`).then(function(response) {
          self.subSubDivisions = response.data;
          self.subSubSubDivisions = [];
          resolve(response.data);
        });
      })
    },
    loadSubSubSubdivisions(subSubDivisionId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/subsubdivisions/${subSubDivisionId}/divisions`).then(function(response) {
          self.subSubSubDivisions = response.data;
          resolve(response.data);
        });
      })
    },
    save(address) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`/addresses`, address)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function(err) {
            reject(err.response.data);
          });
      })
    },
    update(addressId, address) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.put(`/addresses/${addressId}`, address)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function(err) {
            reject(err.response.data);
          });
      })
    },
    formatLine1(address) {
      let line = '';
      if (address.street) {
        line += address.street;
      }
      if (address.number) {
        line += ' ' + address.number;
      }
      if (address.floor) {
        line += ' ' + address.floor;
      }
      if (address.department) {
        line += ' ' + address.department;
      }
      return line;
    },
    formatLine2(address) {
      return this.politicalFormat(address);
    },
    politicalFormat(address) {
      //console.log('format', address);
      let line = '';
      if (address.subSubSubdivision) {
        line += address.subSubSubdivision.name + ', ';
      }
      if (address.subSubdivision) {
        line += address.subSubdivision.name + ', ';
      }
      if (address.subdivision) {
        line += address.subdivision.name + ', ';
      }
      if (address.country) {
        line += address.country.name;
      }
      return line;
    },
    format(address) {
      //console.log('format', address);
      let line = this.formatLine1(address) + ', ' + this.formatLine2(address);
      return line;
    },
  }
});
import { defineStore } from 'pinia'

export const useChatStore = defineStore('chat', {
  state: () => ({
    pending: [], // mensajes pendientes
  }),
  actions: {
    startChat(userId, message) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const payload = {
          userId: userId,
          message: message
        };

        self.$http.post(`chats`, payload).then(function (response) {
          resolve(response.data)
        });
      });
    },
    loadChatWithUserId(userId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`chats/user/${userId}`).then(function (response) {
          resolve(response.data)
        }).catch(function(err) {
          reject(err);
        });
      });
    },
    loadPending() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`chats/pending`).then(function (response) {
          self.pending = response.data;
          resolve(response.data)
        }).catch(function(err) {
          reject(err);
        });
      });
    },
    loadChats() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`chats`).then(function (response) {
          resolve(response.data)
        }).catch(function(err) {
          reject(err);
        });
      });
    }
  }
});

/*
export default {
  actions: {
    order({ commit }, data) {
      return new Promise(function (resolve, reject) {
        http.get(`chats/${data.order_id}`, data.payload).then(function (response) {
          resolve(response.data)
        });
      });
    },
    loadAll({ commit }, data) {
      return new Promise(function (resolve, reject) {
        http.get(`chats`).then(function (response) {
          commit('setOpen', response.data);
          resolve(response.data)
        });
      });
    },
    pending({ commit }, data) {
      return new Promise(function (resolve, reject) {
        http.get(`chats/pending`).then(function (response) {
          commit('setPending', response.data);
          resolve(response);
        }).catch(function(err) {
          console.log(err);
          reject(err);
        });
      });
    },
  }
}*/
<script>
export default {
    data() {
        return {
            error: {
                status: 200,
            }
        }
    },
    methods: {  
        resetErrors() {
            this.error = {
                status: 200,
            };
        },
        validate() {
            this.resetErrors();
            
            if (Object.keys(this.$refs).length === 0) {
                console.error("No hay 'refs' en el formulario");
                return false;
            }

            var valid = true;
            
            for (var ref in this.$refs) {
                let result = this.$refs[ref].validate();
                console.log('validating ref', ref, result);
                if (result === false) {
                    valid = false;
                }
            }

            console.log('validate global', valid);
            return valid;
        }
    },
};
</script>

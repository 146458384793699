<template>
  <div class="page-wrapper">
    <div class="page-title-wrapper" v-if="title">
      <div class="page-title">
        <h1>{{ title }}</h1>
      </div>
      <div class="page-title-actions" v-if="this.$slots.actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="page-content-wrapper" :class="css">
      <div class="sidebar-wrapper" v-if="this.$slots.sidebar">
        <slot name="sidebar"></slot>
      </div>
      <div class="page-content">
        <slot></slot>
      </div>
      <div class="sidebar-right-wrapper" v-if="this.$slots.ridebar">
        <slot name="ridebar"></slot>
      </div>
    </div>
  </div>
</template>
<script lang="js">
export default {
  name: "PageFull",
  props: {
    title: {
      type: [String, Boolean],
      default: false
    },
  },
  computed: {
    css() {
      let withSidebar = false;
      let withRightSidebar = false;

      if (this.$slots.sidebar) {
        withSidebar = true;
      }
      if (this.$slots.ridebar) {
        withRightSidebar = true;
      }

      return {
        'with-sidebar': withSidebar,
        'with-right-sidebar': withRightSidebar
      }
    }
  }
};
</script>
<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <input ref="control" :class="css" type="email" :value="modelValue" @input="emitValue" v-bind="$attrs"
            placeholder="Correo electrónico" />
    </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';

const validateEmail = (email) => {
    var regex = /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
    var s = String(email).toLowerCase().match(regex);
    return s;
};

export default {
    name: "FormEmail",
    mixins: [FormControlMixin],
    components: { FormControlWrapper },
    methods: {
        /*_validateValue(value) {
            return this.validateEmail(value);
        },
        _validate() {
            return this.validateEmail(this.modelValue);
        },*/
        _validateBase(email) {
            if (!validateEmail(email)) {
                this.errors.push("El correo electronico no parece valido");
                return false;
            }
        },
    }
};
</script>
<template>
	<div class="form-button">
		<button ref="control" v-bind="$attrs">
			<slot></slot>
		</button>
	</div>
</template>

<script>

export default {
	name: "FormButton",
	inheritAttrs: false
}

</script>

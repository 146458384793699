<template>
  <ModalWindow title="Editar dirección" id="address-edit">
    <FormWrapper :error="error">
      <FormRow>
        <FormInput ref="name" required label="Nombre (descripcion)" type="text" v-model="item.title" />
      </FormRow>

      <FormRowGrid>
        <FormInput label="Calle" ref="street" name="street" type="text" required v-model="item.street" />
        <FormInput label="Número" ref="number" type="number" min="1" step="1" required="true" v-model.number="item.number" />
      </FormRowGrid>

      <FormRowGrid>
        <FormInput label="Piso" ref="floor" type="number" max="100" step="1" v-model.number="item.floor" />
        <FormInput label="Departamento" ref="department" maxlength="4" type="text" v-model="item.department" />
      </FormRowGrid>

      <FormRow v-if="countries">
        <FormSelect label="País" ref="country" v-model.number="item.countryId" @change="updateProvinces(true)"
          :options="countryOptions">
        </FormSelect>
      </FormRow>

      <FormRow v-if="provinces">
        <FormSelect label="Provincia" ref="province" v-model.number="item.subdivisionId" @change="updateCities(true)"
          :options="level2Options">
        </FormSelect>
      </FormRow>

      <FormRow v-if="cities.length">
        <FormSelect label="Departamento" ref="district" v-model.number="item.subSubdivisionId" :options="level3Options"
          @change="updateLevel4(true)" />
      </FormRow>

      <FormRow v-if="level4.length">
        <FormSelect label="Nivel 4" ref="level4" v-model.number="item.subSubSubdivisionId" :options="level4Options" />
      </FormRow>

      <FormActions>
        <button type="button" @click="close()">Cerrar</button>
        <button type="button" class="primary" v-on:click="save()">Guardar</button>
      </FormActions>
    </FormWrapper>
  </ModalWindow>
</template>

<script>
import { FormWrapperMixin } from "@linkedcode/form-wrapper"
import { useAddressStore } from '../store';

export default {
  name: "AddressAddEditForm",
  id: "address-add-edit-form",
  mixins: [
    FormWrapperMixin
  ],
  props: {
    address: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      item: {
        title: this.address.title || "",
        street: this.address.street || "",
        number: this.address.number || "",
        floor: this.address.floor || "",
        department: this.address.department || "",
        countryId: this.address.country?.id || 10,
        subdivisionId: this.address.subdivision?.id || null,
        subSubdivisionId: this.address.subSubdivision?.id || null,
        subSubSubdivisionId: this.address.subSubSubdivision?.id || null,
      }
    }
  },
  computed: {
    store() {
      return useAddressStore(this.$pinia);
    },
    countries() {
      return this.store.countries;
    },
    provinces() {
      return this.store.subDivisions;
    },
    cities() {
      return this.store.subSubDivisions;
    },
    level4() {
      return this.store.subSubSubDivisions;
    },
    countryOptions() {
      let options = [];
      this.countries.forEach(function (c, idx) {
        options.push({ value: c.id, name: c.name });
      });
      return options;
    },
    level2Options() {
      let options = [];
      this.provinces.forEach(function (c, idx) {
        options.push({ value: c.id, name: c.name });
      });
      return options;
    },
    level3Options() {
      let options = [];
      this.cities.forEach(function (c, idx) {
        options.push({ value: c.id, name: c.name });
      });
      return options;
    },
    level4Options() {
      let options = [];
      this.level4.forEach(function (c, idx) {
        options.push({ value: c.id, name: c.name });
      });
      return options;
    }
  },
  methods: {
    close() {
      this.reject("Closed");
    },
    updateProvinces(clear) {
      if (clear === true) {
        this.item.subdivisionId = null;
        this.item.subSubdivisionId = null;
        this.item.subSubSubdivisionId = null;
      }
      return this.store.loadSubdivisions(this.item.countryId);
      
    },
    updateCities(clear) {
      if (clear === true) {
        this.item.subSubdivisionId = null;
        this.item.subSubSubdivisionId = null;
      }
      return this.store.loadSubSubdivisions(this.item.subdivisionId);
    },
    updateLevel4(clear) {
      if (clear === true) {
        this.item.subSubSubdivisionId = null;
      }
      return this.store.loadSubSubSubdivisions(this.item.subSubdivisionId);
    },
    save() {
      const self = this;

      if (!this.validate()) {
        return;
      }

      if (this.address.id) {
        this.store.update(this.address.id, this.item)
          .then(function(msg) {
            self.resolve(msg);
          })
          .catch(function(error) {
            self.error = error
          });
      } else {
        this.store.save(this.item)
          .then(function(msg){
            self.resolve(msg);
          })
          .catch(function(error) {
            self.error = error;
          });
      }
    },
  },
  mounted() {
    const self = this;
    this.store.loadCountries().then(function() {
      if (self.item.countryId) {
        self.updateProvinces().then(function() {
          if (self.item.subdivisionId) {
            self.updateCities().then(function () {
              if (self.item.subSubdivisionId) {
                self.updateLevel4();
              }
            });
          }
        });
      }
    });
  },
};
</script>
<script>
export default {
    data() {
        return {
            errors: [],
            apiErrors: []
        }
    },
    props: {
        modelValue: {
        },
        label: {
            type: String,
            default: ""
        }
    },
    emits: ['update:modelValue'],
    computed: {
        css() {
            return {
                'form-input-error': (this.errors.length > 0 || this.apiErrors.length > 0),
                'form-input-valid': this.errors.length === 0 && this.apiErrors.length === 0,
            };
        }
    },
    methods: {
        addError(err) {
            console.log('addError', err);
            this.apiErrors.push(err);
        },
        emitValueCheckbox(e) {
            let value = [];
            console.log(this.$refs);
            for (var refname in this.$refs) {
                var ref = this.$refs[refname];
                console.log('checked', refname, ref[0].checked);
                if (ref[0].checked === true) {
                    value.push(ref[0].value);
                }
            }
            this.$emit('update:modelValue', value);
        },
        emitValue(e) {
            let value = e.target.value;
            console.log('emitvalue', value);
            this.$emit('update:modelValue', value);
            this._reset();
            this._validateValue(value);
        },
        validate() {
            this._reset();
            return this._validateValue(this.modelValue);
        },
        _validateValue(value) {
            return this._validateBase(value);
        },
        _reset() {
            this.errors.length = 0;
            this.apiErrors.length = 0;
        },
        _validate() {
            this._validateBase(this.modelValue);
        },
        _validateBase(value) {
            console.log('_validateBase', value);

            let control = this.$refs['control'];

            if (control.required === true) {
                console.log("required", value, typeof value);
                if (value === "") {
                    if (control.type === "number") {
                        this.errors.push("Ingrese un número");
                    } else if (control.nodeName === "SELECT") {
                        this.errors.push("Debe elegir una opción.");
                    } else {
                        this.errors.push("Este campo es requerido.");
                    }
                }
            }

            if (typeof control.maxLength != "undefined" && control.maxLength > -1) {
                console.log("maxLength", control.maxLength, value);
                if (value.length > parseInt(control.maxLength)) {
                    this.errors.push("Longitud maxima excedida: " + control.maxLength);
                }
            }

            if (typeof control.minLength != "undefined" && control.minLength > -1) {
                console.log("minLength", control.minLength, value);
                if (value.length < parseInt(control.minLength)) {
                    this.errors.push("Longitud minima no alcanzada: " + control.minLength);
                }
            }

            if (typeof control.min !== "undefined") {
                console.log("min", control.min, value);
                if (parseFloat(value) < parseFloat(control.min)) {
                    this.errors.push("Valor minimo permitido: " + control.min);
                }
            }

            if (typeof control.max !== "undefined") {
                console.log("max", control.max, value);
                if (parseFloat(value) > parseFloat(control.max)) {
                    this.errors.push("Valor maximo permitido: " + control.max);
                }
            }

            return (this.errors.length === 0);
        },
        findRefByName(refName) {
            let obj = this
            while (obj) {
                if (obj.$refs[refName]) {
                    return obj.$refs[refName]
                }
                obj = obj.$parent
            }
            return undefined
        }
    }
}
</script>